.recipe {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}

.recipe__container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: stretch;
  width: 1180px;
}

.recipe__info {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
}

.recipe__image {
  width: 300px;
  height: 300px;
  background: url("./images/egg_background.png");
}

.recipe__image img {
  position: absolute;
  margin-top: 60px;
  margin-left: 60px;
  border-radius: 100px;
  filter: drop-shadow(-8px -8px 18px rgba(255, 255, 255, 0.5))
    drop-shadow(8px 8px 18px rgba(149, 157, 173, 0.2));
  width: 140px;
  height: 140px;
}

.recipe__description {
}

.recipe__meal-name {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: white;
  text-shadow: rgb(247, 148, 29) 1px 0px 0px,
    rgb(247, 148, 29) 0.540302px 0.841471px 0px,
    rgb(247, 148, 29) -0.416147px 0.909297px 0px,
    rgb(247, 148, 29) -0.989992px 0.14112px 0px,
    rgb(247, 148, 29) -0.653644px -0.756802px 0px,
    rgb(247, 148, 29) 0.283662px -0.958924px 0px,
    rgb(247, 148, 29) 0.96017px -0.279415px 0px;
  margin-bottom: 30px;
}

@media (max-width: 687px) {
  .recipe__meal-name {
    font-size: 40px;
    font-weight: 700;
  }
}

.recipe__add-like-button {
  cursor: pointer;
  display: unset;
  width: 22.25px;
  height: 25px;
  margin-left: 30px;
}

.recipe__remove-like-button {
  display: unset;
  cursor: pointer;
  width: 22.25px;
  height: 25px;
  margin-left: 30px;
}

.recipe__meal-ingridients {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
  margin-left: -10px;
}

.recipe__meal-instructions {
}

.recipe__meal-instructions-title {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #4e598c;
  margin-bottom: 30px;
}

.recipe__meal-instructions-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: nowrap;
  margin-bottom: 50px;
}

.recipe__meal-instructions-step-counter {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  display: flex;
  align-items: center;
  color: white;
  text-shadow: rgb(247, 148, 29) 1px 0px 0px,
    rgb(247, 148, 29) 0.540302px 0.841471px 0px,
    rgb(247, 148, 29) -0.416147px 0.909297px 0px,
    rgb(247, 148, 29) -0.989992px 0.14112px 0px,
    rgb(247, 148, 29) -0.653644px -0.756802px 0px,
    rgb(247, 148, 29) 0.283662px -0.958924px 0px,
    rgb(247, 148, 29) 0.96017px -0.279415px 0px;
  margin-right: 30px;
}

.recipe__meal-instructions-step-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 170%;
}

.recipe__similar-recipies-text {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #4e598c;
  margin-bottom: 30px;
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .recipe__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-content: stretch;
    width: 90%;
  }

  .recipe__meal-instructions-step-text {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 170%;
    width: 70%;
  }
}
