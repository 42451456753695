.subscribe-container {
  max-width: 1170px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin: 100px auto 0 auto;
}

.small-subscribe-box {
  width: 300px;
  height: 392px;
  background: #F5F5FA;
  border-radius: 33px;
  box-shadow: 0px 10px 24px rgba(191, 191, 191, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-subscribe-box {
  width: 370px;
  height: 500px;
  background: #F5F5FA;
  border-radius: 33px;
  box-shadow: 0px 10px 24px rgba(191, 191, 191, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.size {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #F7941D;
  margin-top: 20px;
}

.size-l {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #F7941D;
}

.main-size {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 44px;
  color: #F7941D;
  margin-top: 50px;
}

.subscribe-description {
  width: 250px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  font-variant: small-caps;
  color: #000000;
  margin-top: 50px;
}

.main-subscribe-description {
  width: 250px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  font-variant: small-caps;
  color: #000000;
  margin-top: 50px;
}

.subscribe-price {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-variant: small-caps;
  color: #000000;
  margin-top: 10px;
}

.main-subscribe-price {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  font-variant: small-caps;
  color: #000000;
  margin-top: 80px;
}

.get-started {
  color: #FFFFFF;
  padding: 5px 15px;
  background: #F7941D;
  border: 1px solid #F7941D;
  box-shadow: 0px 22px 44px -12px rgba(247, 148, 29, 0.35);
  border-radius: 10px;
  font-size: 15px;
}

.banana img {
  width: 100px;
  margin-top: -40px;
  margin-left: 45px;
  position: absolute;
}

.orangeFruit img {
  position: absolute;
  margin-left: -150px;
}

.plantMain img {
  width: 170px;
  position: absolute;
  margin-top: -170px;
  margin-left: -180px;
}

@media (max-width: 400px) {
  .subscribe-container {
    gap: 50px;
  }
}