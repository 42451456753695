@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.footer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  width: 1180px;
}

.media-box {
  margin: 30px 50px 30px 0px;
}

.logo-box {
  background-image: url("./images/footer_logo.svg");
  width: 220px;
  height: 50px;
}

.social-box {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
}

.social-box img {
  transition: 0.5s;
}

.social-box img:hover {
  transition: 0.5s;
  transform: scale(1.1);
}

.links-box {
  margin: 30px 30px 30px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: flex-start;
}

.links-column {
  font-family: Montserrat, sans-serif;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.links-column p {
  margin-bottom: 5px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #4e598c;
  text-decoration: none;
}

.links-column a {
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  color: black;
  text-decoration: none;
}

.links-column li {
  list-style-type: none;
}

.email-box {
  margin: 30px 50px 30px 50px;
}

.email-box img {
  position: absolute;
  margin-left: 250px;
}

.email-box p {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  color: #4e598c;
  text-decoration: none;
}

.email-input {
  outline: 0;
  border: 0;
  border-radius: 16px;
  font-family: Montserrat, sans-serif;
  padding: 10px;
  width: 205px;
  height: 50px;
  font-size: 14px;
}

.pinterest {
  width: 24px;
  height: 24px;
}

.facebook {
  margin-left: -5px;
}

.email-form {
  background-color: white;
  border-radius: 14px;
  width: 300px;
  height: 50px;
  box-shadow: 0px 10px 24px rgba(191, 191, 191, 0.8);

  a {
    text-decoration: none;
    color: black;
  }
}

.social-box {
  div {
    a {
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.email-send {
  font-family: Montserrat, sans-serif;
  position: absolute;
  margin-left: 0px;
  margin-top: 13px;
  border-bottom: 1.7px solid black;
}

.copyright-line {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

@media (max-width: 1439px) {
  .footer-container {
    width: 81%;
  }

  .logo-box {
    background-image: url("./images/footer_logo_small.svg");
    background-repeat: no-repeat;
    width: 67px;
    height: 80px;
  }
}

@media (max-width: 1023px) {
  .media-box {
    position: absolute;
    left: 5%;
  }

  .social-box {
    margin-top: 75px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  .social-box img {
    margin-right: 30px;
  }

  .links-column {
    position: relative;
    left: 40px;
  }

  .email-box {
    margin: 30px 50px 30px 50px;
  }

  .email-box img {
    position: absolute;
    margin-top: 10px;
    margin-left: 210px;
  }

  .email-box p {
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    color: #4e598c;
    text-decoration: none;
  }

  .email-input {
    outline: 0;
    border: 0;
    border-radius: 16px;
    font-family: Montserrat, sans-serif;
    padding: 10px;
    width: 180px;
    height: 50px;
    font-size: 14px;
  }

  .email-form {
    border-radius: 14px;
    width: 270px;
    height: 50px;
    box-shadow: 0px 10px 24px rgba(191, 191, 191, 0.8);

    a {
      text-decoration: none;
      color: black;
    }
  }

  .email-send {
    font-family: Montserrat, sans-serif;

    position: absolute;
    margin-left: 0px;
    margin-top: 13px;
    border-bottom: 1.7px solid black;
  }

  .copyright-line {
    margin-top: 200px;
    left: 10%;
    position: absolute;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
  }
}

@media (max-width: 1023px) {
  .email-box {
    position: relative;
    right: -60px;
  }
}

@media (max-width: 574px) {
  .links-column:nth-child(3) {
    position: relative;
    left: 100px;
  }
}

@media (max-width: 519px) {
  .copyright-line {
    margin-top: 0px;
    left: 0;
    position: unset;
    font-family: Montserrat, sans-serif;
    font-size: 16px;
  }

  .email-box {
    position: relative;
    right: 20%;
  }

  .social-box {
    img {
      margin: 0;
    }
  }
}
