.reset-pass__container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .reset-pass__FP-logo {
    position: absolute;
    top: 50px;
    left: 50px;
    height: 65px;
    max-width: 310px;
    width: 100%;
  }

  .reset-pass__background-image {
    position: absolute;
    z-index: -100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("img/bg_reg_pages.jpg");
  }

  .reset-pass__form {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    background-color: #fff;
    border-radius: 40px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-height: 600px;
    height: 100%;

    .reset-pass__form-title {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 120%;
      color: #000;
      padding: 40px 0 30px 0;
    }

    ul {
      padding-top: 5px;
      margin-left: -150px;
      padding-bottom: 30px;

      li {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        color: #474747;
        list-style-type: circle;
      }
    }

    .reset-pass__form-btn {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000;
      margin-top: 60px;
      margin-bottom: 20px;
    }

    .reset-pass__form-btn:hover {
      background: #FFFFFF;
      border: 1px solid #F7941D;
      box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);

      div {
        color: #F7941D;
      }
    }

    .reset-pass__form-btn {
      transition: all .2s;
      height: 60px;
      width: 80%;
      border-radius: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 60px;
      background: #F7941D;
      box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
      text-align: center;
      cursor: pointer;
      border: none;

      div {
        font-family: 'Karla', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 160%;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 1;
      }
    }

    .reset-pass__form-btn {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000;
      margin-top: 60px;
      margin-bottom: 20px;
    }

    .reset-pass__form-btn:hover {
      background: #FFFFFF;
      border: 1px solid #F7941D;
      box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);

      div {
        color: #F7941D;
      }
    }

    .reset-pass__form-btn:disabled {
      transition: all .2s;
      height: 60px;
      width: 80%;
      border-radius: 40px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      text-align: center;
      margin-top: 60px;

      div {
        font-family: 'Karla', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 160%;
        color: #FFFFFF;
        flex: none;
        order: 0;
        flex-grow: 1;
      }
      background: #F7941D;
      opacity: 0.4;
      box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
    }

    .reset-pass__form-btn:focus {
      outline: none;
      border: none;
    }
  }
}