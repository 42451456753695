a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 20px;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
  width: 1180px;
}

.header__logo-n-links {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
}

.header__logo {
}

.header__links {
  display: flex;
  flex-direction: row;
}

.header__link {
  font-family: Montserrat, sans-serif;
  margin-right: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #1c1712;
  cursor: pointer;
}

.header__link:hover {
  text-decoration: none;
  color: #1c1712;
}

.header__search-n-profile {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
}

.header__search-bar {
  margin-right: 50px;
}

.header__search-form {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
}

.header__search-input,
.header__search-input:hover,
.header__search-input:active,
.header__search-input:focus {
  width: 160px;
  height: 30px;
  opacity: 0.5;
  border: 1px solid #1c1712;
  border-radius: 20px;
  padding-left: 15px;
  color: #1c1712;
  font-size: 14px;
}

.header__search-button {
  position: absolute;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 25px;
  background-color: transparent;
  margin-right: 5px;
  display: flex;
  background-image: url("./images/search_icon.svg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.header__burger-icon {
  display: none;
  width: 0;
  height: 0;
}

.header__profile {
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.header__burgernav {
  display: none;
  width: 0;
  height: 0;
}

.header__profile img {
  border-radius: 30px;
  box-shadow: 1px 1px 20px 10px rgba(248, 150, 31, 0.2);
}

@media (max-width: 1023px) {
  .header__search-bar {
    margin-right: 20px;
  }

  .header__search-button {
    position: absolute;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 25px;
    background-color: transparent;
    margin-right: 5px;
    display: flex;
    background-image: url("./images/search_icon_big.svg");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .header__search-input {
    display: none;
    width: 40px;
  }

  .header__search-form:hover .header__search-input {
    display: flex;
    width: 160px;
    height: 36px;
    border: 1px solid #000000;
    border-radius: 20px;
    padding-left: 15px;
    color: #1c1712;
    font-size: 14px;
  }

  .header__search-input:focus {
    display: flex;
    width: 160px;
    height: 36px;
    border: 1px solid #1c1712;
    border-radius: 20px;
    padding-left: 15px;
    color: #1c1712;
    font-size: 14px;
  }
}

@media (max-width: 639px) {
  .header-container {
    width: 390px;
  }

  .header__profile {
    display: none;
    width: 0;
    height: 0;
  }

  .header__burgernav {
    cursor: pointer;
    display: unset;
    width: 40px;
    height: 40px;
    margin: 0 15px;
  }

  .header__link {
    display: none;
  }

  .header__logo-n-links {
    padding: 0 15px;
  }
}
