@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.recipes-container {
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  //padding-left: 15%;
  //padding-right: 15%;
  //background-image: url("../../dashboard/images/background.png");
  //background-color: #F8F8FA;
}

.tabs-box {
  width: 62%;
}

.tab-custom-active {
  font-family: Montserrat, sans-serif !important;
  border: 1px solid #f7941d !important;
  border-radius: 50px 50px 0px 0px !important;
  background-color: #f7941d !important;
  font-weight: 600 !important;
  color: white !important;
  font-size: 22px !important;
}

.tab-custom {
  font-family: Montserrat, sans-serif !important;
  border: 1px solid #ffffff !important;
  border-radius: 40px 40px 0px 0px !important;
  background-color: #ffffff !important;
  font-weight: 500 !important;
  color: #4e598b !important;
  font-size: 22px !important;
}

.tab-custom label {
  padding-top: 200px !important;
}

.recipe-boxes {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 70%;
  margin-left: 15%;
}

.recipe-box {
  cursor: pointer;
  width: 220px;
  height: 230px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  margin: 10px;
  background-color: white;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: center;
}

.recipe-box-link {
  cursor: pointer;
  width: 220px;
  height: 230px;
  padding: 15px;
  //background-color: burlywood;
}

.recipe-box:hover {
  box-shadow: 0px 10px 24px rgba(191, 191, 191, 0.8);
}

.add-like-button {
  display: unset;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 22.25px;
  height: 25px;
}

.remove-like-button {
  display: none;
}

.recipe-box:hover .remove-like-button {
  display: unset;
  cursor: pointer;
  position: absolute;
  width: 22.25px;
  height: 25px;
  right: 20px;
  top: 20px;
}

.meal-image {
  border-radius: 100px;
  width: 140px;
  height: 140px;
  margin: 20px;
}

.meal-title {
  font-size: 18px;
  max-width: 220px;
  width: 100%;
}

.tabs-change {
  display: flex;
  flex-direction: column;
}

[type="button"]:not(:disabled) {
  cursor: pointer;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
}

.load-more-button {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 115px;
  height: 20px;

  text-align: center;
  text-underline: #4e598b;
  text-decoration: underline;
  color: #4e598b;
  font-size: 18px;

  cursor: pointer;
}

.loading {
  margin-top: 20px;
  text-align: center;
  font-size: 18px;
  height: 543px;
}

@media (min-width: 1920px) {
  .tabs-box {
    width: 1140px;
  }

  .recipe-boxes {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 1420px;
    margin-left: 1%;
  }

  .random {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: unset;
    margin-left: 0%;
  }
}

@media (max-width: 1920px) {
  .tabs-box {
    width: 1180px;
  }

  .recipe-boxes {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
    margin-left: 15%;
  }

  .random {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: unset;
    margin-left: 0%;
  }
}

@media (max-width: 1780px) {
  .tabs-box {
    width: 940px;
  }

  .recipe-boxes {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 70%;
    margin-left: 15%;
  }

  .random {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: unset;
    margin-left: 0;
  }
}

@media (max-width: 1439px) {
  .tabs-box {
    width: 940px;
  }

  .recipe-boxes {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 81%;
    margin-left: 10%;
  }

  .random {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    width: unset;
    margin-left: 0%;
  }
}

@media (max-width: 1250px) {
  .tabs-box {
    width: 690px;
  }

  .random {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: unset;
    margin-left: 0%;
  }
}

@media (max-width: 953px) {
  .tabs-box {
    width: 640px;
  }

  .recipe-boxes {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 95%;
    margin-left: 5%;
  }

  .recipe-box {
    //cursor: pointer;
    width: 220px;
    height: 230px;
    margin: 5px;
  }
}

@media (max-width: 791px) {
  .tabs-box {
    width: 500px;
  }
}

@media (max-width: 650px) {
  .recipe-box {
    cursor: pointer;
    width: 220px;
    height: 230px;
    margin: 10px;
  }
}

@media (max-width: 639px) {
  .tabs-box {
    width: 490px;
  }
}

@media (max-width: 639px) {

  .add-like-button {
    display: unset;
    cursor: pointer;
    position: absolute;
    width: 22.25px;
    height: 25px;
    margin-left: 50px;
  }

  .recipe-boxes {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0;
  }

  .recipe-box {
    width: 150px;
    height: 200px;
  }

  .recipe-box:hover .remove-like-button {
    display: unset;
    cursor: pointer;
    position: absolute;
    width: 22.25px;
    height: 25px;
    margin-left: 50px;
    margin-top: 10px;
  }

  .recipe-box img:nth-child(2) {
    border-radius: 100px;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .meal-title {
    margin: 0;
    font-size: 16px;
  }
}

@media (max-width: 632px) {
  .tabs-box {
    width: 320px;
  }
}
