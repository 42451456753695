.edit__form {
  display: flex;
  max-width: 955px;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 100px;
  margin: 30px 0;

  .edit__input-container {
    display: flex;
    flex-direction: column;

    label {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 130%;
      color: #4E598C;
      margin: 0 0;
      padding: 0 0;
    }

    .MuiInputBase-root {
      max-width: 130px;
      width: 100%;
      height: 40px;
      border-radius: 10px !important;
      border: 1px solid #5b8ecb !important;

      color: #110B11 !important;
      opacity: 0.8 !important;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #F0F0F0 !important;
      border-radius: 10px !important;
      transition: all .2s ease-in-out;
    }

    div {
      display: flex;

      .edit__input {
        max-width: 50px;
        width: 100%;
        height: 40px;
        border: 1px solid #5b8ecb !important;

        color: #110B11 !important;
        opacity: 0.8 !important;
        text-align: center;
      }

      .edit__input:focus {
        outline: none;
      }

      .left__arrow {
        width: 40px;
        height: 40px;
        border-radius: 10px 0 0 10px;
        border: 1px solid #5b8ecb !important;
        background-color: #5b8ecb;
        color: #fff;
        cursor: pointer;
        transition: .3s;
      }

      .right__arrow {
        width: 40px;
        height: 40px;
        border-radius: 0 10px 10px 0;
        border: 1px solid #5b8ecb !important;
        background-color: #5b8ecb;
        color: #fff;
        cursor: pointer;
        transition: .3s;
      }

      .left__arrow:hover {
        background-color: #fff;
        color: #5b8ecb;
        transition: .3s;
      }

      .right__arrow:hover {
        background-color: #fff;
        color: #5b8ecb;
        transition: .3s;
      }

      .left__arrow:disabled, .right__arrow:disabled {
        background-color: #fff;
        color: #5b8ecb;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}