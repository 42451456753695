.recipes__parameters {
  display: flex;
  margin-bottom: 50px;
}

.recipes__parameters-icon {
  display: flex;
  align-items: stretch;
  margin-right: 50px;
  justify-content: space-evenly;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;

  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }

  p {
    font-family: "Karla", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
  }
}
