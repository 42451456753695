.planner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
}
.planner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: stretch;
}
.planner-settings__container-top {
  display: flex;
  justify-content: left;
  width: 1180px;
}
.planner__calendar-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 1240px;
  padding-bottom: 60px;
  .arrow {
    color: #aea08e;
    font-size: 42px;
    width: 4%;
    margin: auto;
    cursor: pointer;
  }
}
.planner__vertical-line {
  width: .5px;
  height: 102%;
  background: #4E598C;
}
.planner__line {
  width: 24px;
  height: .5px;
  background: #4E598C;
}
.planner__cont-for-setting {
  transition: all .2s;
  height: 48px;
  width: 77px;
  display: flex;
  align-items: center;
  justify-content: right;
  flex-direction: row;
  padding-left: 10px;
  background: #FFFFFF;
  border: 1px solid #4E598C;
  border-radius: 8px;
  p {
    margin-right: 10px;
    color: #1C1712;
    font-size: 15px;
  }
}
.planner-con__arrow-left {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background: url("./images/arrow_left_side.svg");
}
.planner-con__arrow-right {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background: url("./images/arrow_right_side.svg");
}
.planner-calendar__row {
  display: flex;
  width: 1100px;
}
.planner-con__max_calories-input {
  transition: all .2s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 199px;
  height: 45px;
  margin-right: 100px;
  p {
    padding-top: 15px;
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: #4E598C;
  }
}
.planner-con__max-cal__toggle-line {
  width: 24px;
  height: 48px;
}
.planner-con__max-cal__toggle-up-case {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
.planner-con__max-cal__toggle-down-case {
  cursor: pointer;
  width: 24px;
  height: 24px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.planner-con__max-calories {
  border: none;
  border-radius: var(--ntp-realbox-border-radius);
  color: var(--search-box-text);
  font-family: inherit;
  font-size: inherit;
  height: 100%;
  outline: none;
  width: 30px;
  margin-right: 10px;
}
.planner__mealtime {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: flex-start;
  background: #FFFFFF;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 40px;
  p {
    font-family: Courier, monospace;
    font-style: normal;
    font-weight: normal;
    text-align: left;
  }
}
.planner__mealtime-row {
  z-index: 100;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  p {
    line-height: 130%;
  }
}
.planner__mealtime-row-drop {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  p {
    line-height: 130%;
  }
}
.planner__mealtime-row:hover {
  cursor: pointer;
  background: rgba(163, 168, 195, 0.3);
}
.planner__mealtime-row-info {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
  z-index: 100;
  z-index: 1001;
}
.planner__mealtime-row-time {
  width: 130px;
  height: 31px;
  display: flex;
  align-items: center;
  p {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    color: #4E598C;
    height: 20px;
    margin: -10px 0 0 0;
  }
  .icon-arrow {
    margin: 0 5px;
    color: #aea08e;
    transition: 0.3s;
  }
  .active {
    transition: 0.3s;
    transform: rotate(90deg);
  }
}
.planner__mealtime-row-mealname {
  width: 500px;
  height: 30px;
  color: #1C1712;
  p {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
  }
}
.planner__mealtime-row-ccal {
  width: 150px;
  height: 22px;
  p {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: #1C1712;
  }
}
.planner__mealtime-row-hidden-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  transition: 0.3s;
  height: 100%;
  border: 1px solid rgb(128, 123, 123);
  padding: 0.5rem;
  border-radius: 5px;
}

.planner__mealtime-row-show {
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  transition: all .3s ease-in-out;
  margin-top: -385px;
  align-items: center;
  p {
    line-height: 130%;
  }
  opacity: 0;

  div {
    transition: all .2s ease-in-out;
    opacity: 0;
  }
}

.show-row {
  margin-top: 0px;
  transition: all .3s ease-in-out;
  opacity: 1;

  div {
    transition: all .2s ease-in-out;
    opacity: 1;
    line-height: 130%;
  }
  height: 0;
  overflow: hidden;
  transition: height ease 2s;
}

.planner__mealtime-row-show:hover {
  cursor: pointer;
  background: rgba(163, 168, 195, 0);
}
.planner__mealtime-row-hidden-info:hover {
  background: rgba(163, 168, 195, 0);
}

.planner__mealtime-row-hidden-image {
  width: auto;
}
.planner__mealtime-row-hidden-image img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  margin-right: 20px;
}
.planner__mealtime-row-hidden-mealinfo {
  width: 400px;
  padding: 20px 0;
}
.planner__mealtime-row-hidden-mealinfo p {
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}
.planner__mealtime-row-hidden-text {
  font-family: Montserrat, sans-serif;
  font-size: 14px;
}
.planner-field__handle-click {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 45px;
  width: 253px;
  cursor: pointer;
  p {
    font-family: 'Montserrat', serif;
    font-style: normal;
    font-size: 17px;
    line-height: 130%;
    color: #4E598C;
    order: 1;
    padding-top: 15px;
  }
}
.planner-field__handle-click_no-vision {
  transition: all 1s;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.planner-container__settings {
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 1180px;
  padding-bottom: 40px;
}
.week-day__date {
  margin-bottom: 0;
  width: 140px;
  height: 21px;
  font-weight: 300;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  color: #1C1712;
}
.planner-settings__on {
  transition: all .3s;
  transform: rotate(-90deg);
  height: 30px;
  width: 30px;
  background: url("./images/setting.svg");
}
.planner-settings__off {
  transition: all .3s;
  transform: rotate(90deg);
  height: 30px;
  width: 30px;
  background: url("./images/setting.svg");
}
.planner-con {
  transition: all 1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  align-content: stretch;
  width: 1180px;
}
.planner-con__no-vision  {
  transition: all 1s;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}
.menu-select__planner .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 2px solid #A3A8C3 !important;
  border-radius: 8px !important;
  width: 180px !important;
}
.menu-item__planer {
  background: #216022 !important;
}
.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  margin-left: 5px;
  width: 160px !important;
  border-radius: 12px !important;
}
@media (max-width: 1100px) {
  .planner-settings__container-top {
    transition: all .2s;
    width: 79%;
  }
  .planner-container__settings {
    width: 90%;
  }
  .planner-con {
    width: 90%;
  }
  .planner-container {
    width: 99%;
  }
  .planner__calendar-row {
    transition: all .3s;
    width: auto;
  }
  .planner-calendar__row {
    transition: all 1s;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-left: 10px;
  }
  .week-day__date {
    transition: all .2s;
    margin-top: 15px;
  }
  .planner__mealtime {
    transition: all .2s;
    width: 80%;
    p {
      font-size: 18px;
    }
    .planner__mealtime-row-mealname {
      width: 400px;
    }
  }
}
@media (max-width: 1000px) {
  .planner__mealtime {
    padding-top: 40px;
    padding-bottom: 40px;
    p {
      padding-top: 6px;
      font-size: 16px;
    }
    .planner__mealtime-row-mealname {
      width: 350px;
    }
  }
}
@media (max-width: 800px) {
  .planner__mealtime {
    padding-top: 30px;
    padding-bottom: 30px;
    p {
      font-size: 15px;
    }
    .planner__mealtime-row-mealname {
      width: 300px;
    }
    .planner__mealtime-row-ccal {
      width: 100px;
    }
  }
}
@media (max-width: 700px) {
  .planner__mealtime {
    p {
      font-size: 12px;
    }
  }
}
@media (max-width: 500px) {
  .planner__mealtime-row {
    padding-left: 30px;
    padding-right: 30px;
  }
  .planner__mealtime {
    padding-top: 20px;
    padding-bottom: 20px;
    p {
      font-size: 10px;
    }
    .planner__mealtime-row-time {
      width: 50px;
    }
    .planner__mealtime-row-mealname {
      width: auto;
    }
    .planner__mealtime-row-ccal {
      width: auto;
    }
  }
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px;
  border-radius: 5px;
  margin-right: 150px;
}

.parent-container {
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: height ease 0.5s;
  display: flex;
  justify-content: space-around;
}
