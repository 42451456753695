.profile-input__box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 380px;
  width: 100%;
  position: relative;

  .profile-input__label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 130%;
    color: #4E598C;
    margin: 0 0;
    padding: 0 0;
  }

  .profile-input__input {
    font-family: 'Montserrat';
    font-style: normal;
    padding: 0 15px;
    max-width: 380px;
    width: 100%;
    height: 60px;
    border: 2px solid #A3A8C3;
    border-radius: 8px;
    transition: 0.5s;
  }

  .profile-input__input:focus {
    outline: #abb1d3;
  }

  .eye-container {
    position: absolute;
    left: 90%;
    top: 50%;
  }

  .profile-input__error {
    position: absolute;
    top: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 160%;
    color: #E3262F;
  }
}
