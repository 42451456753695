.profile-password__container {
  max-width: 1170px;
  width: 100%;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  gap: 60px;

  .profile-password__current-password-container {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .forgot-password {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.408px;
      text-decoration-line: underline;
      color: #4E598C;
      cursor: pointer;
    }
  }

  .profile-password__new-password-container {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;

    ul {
      padding-top: 5px;

      li {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        color: #474747;
        list-style-type: circle;
      }
    }
  }

  .profile-password__btn-container {
    display: flex;
    justify-content: flex-end;
  }
}

.password-modal__text-container {
  display: flex;
  flex-direction: column;

  p {
    margin: 0 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #1C1712;
  }

  .new-email-text {
    margin: 0 0;
    color: #F7941D;
    cursor: pointer;
    padding-left: 5px;
  }
}

.password-modal__btn-container {
  display: flex;
  justify-content: flex-end;
}