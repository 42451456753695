.RegisterComponent {
  margin: 50px 0;
  border-radius: 40px;
  padding: 40px 10px 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 640px;
  background: #F8F8FA;
}
.JoinNowTextLine {
  display: flex;
  justify-content: center;
  margin-top: 55px;
  font-family: 'Montserrat', serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #4E598C;
  a {
    transition: all .2s;
    margin-left: 10px;
    color: #4E598C;
    font-style: normal;
    font-weight: 500;
    text-decoration-line: underline;
    flex: none;
    flex-grow: 0;
  }
  a:hover {
    color: #5b8ecb;
  }
}
.login__page_to_forgetPage {
  transition: all .2s;
  margin-top: 10px;
  margin-right: 312px;
  font-family: 'Montserrat', serif;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.408px;
  color: #4E598C;
  margin-left: 10px;
  font-weight: 500;
  text-decoration-line: underline;
  flex: none;
  cursor: pointer;
  a:hover {
    color: #5b8ecb;
  }
}
.JoinHeader {
  font-family: Montserrat, serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  letter-spacing: 0;
  text-align: left;
  margin-bottom: 30px;
}
.back-reg-page__box {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  align-self: center;
  height: 100vh;
}
.back-reg-page__box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: all 2s;
  width: 100%;
  //background: url("images/bg_reg_pages.jpg") no-repeat;
  height: 100%;
}

.background__image {
  position: absolute;
  z-index: -100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("images/bg_reg_pages.jpg") no-repeat;
  background-size: cover;
}

.field-input {
  width: 80%;
  height: 102px;
  margin-top: 30px;
  position: relative;
}
.field-input__p {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  color: #4E598C;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-bottom: 0;
}
.field-input__box{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 15px;
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  border: 1px solid #A3A8C3;
  border-radius: 8px;
  flex: none;
  order: 1;
  outline: none;
  align-self: stretch;
  flex-grow: 0;
}
.correctInput {
  display: flex;
  align-items: center;
  color: green;
}
.incorrectInput {
  display: flex;
  align-items: center;
  color: red;
}
.visionIconError {
  height: 15px;
  width: 15px;
  margin-right: 5px;
  background-image: url("images/incorrect_input.svg");
}
.visionIconOk {
  width: 13px;
  height: 9px;
  margin-right: 5px;
  background-image: url("images/ok_input.svg");
}
.VisionPasswordButton {
  position: absolute;
  transition: all .2s;
  width: 28px;
  height: 20px;
  top: 55px;
  margin-left: 92%;
  background-image: url("images/vision_icon.svg");
}
.NoVisionPasswordButton {
  position: absolute;
  transition: all .2s;
  width: 26px;
  height: 24px;
  top: 55px;
  margin-left: 92%;
  background-image: url("images/no_vision_icon.svg");
}
.registerButton {
  transition: all .2s;
  height: 60px;
  width: 80%;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top:40px;
  background: #F7941D;
  box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
  text-align: center;
  cursor: pointer;
  border: none;
  div {
    font-family: 'Karla', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 160%;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 1;
  }
}
.registerButton:hover {
  background: #FFFFFF;
  border: 1px solid #F7941D;
  box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
  div {
    color: #F7941D;
  }
}
.registerButton:disabled {
  transition: all .2s;
  height: 60px;
  width: 80%;
  border-radius: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  margin-top: 40px;
  div {
    font-family: 'Karla', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 160%;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 1;
  }
  background: #F7941D;
  opacity: 0.4;
  box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
}
.signUpWitchGoogle {
  transition: all .3s;
  display: flex;
  height: 38px;
  width: 260px;
  justify-content: space-between;
  margin-top: 40px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0);
  p {
    font-family: Karla, serif;
    font-size: 24px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0;
    text-align: left;
  }
}
.iconGoogle {
  width: 32px;
  height: 32px;
  background-image: url("images/google_logo.svg");
}
.signUpWitchGoogle:hover {
  border-bottom: 1px solid #F7941D;
}
.CheckEmailWindow {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  width: 100%;
  height: 100%;
 z-index: 100;
}
.CheckEmailWindow_none {
  transition: all 2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  width: 100%;
  height: 100%;
  background: white;
  margin-top: -20px;
}
.CheckEmailWindow-box-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 580px;
  height: auto;
  text-align: center;
  padding: 50px 30px;
  background: white;
  border-radius: 40px;
}
.OrangeIMG {
  width: 220px;
  height: 210px;
  background-image: url("./images/orange.svg");
}
.CheckEmailWindow-box-text1 {
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 49px;
  color: #1C1712;
  margin-bottom: 60px;
}
.CheckEmailWindow-box-text2 {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #1C1712;
  margin-bottom: 20px;
}
.CheckEmailWindow-box-text3 {
  font-family: 'Montserrat', serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #1C1712;
}
.CheckEmailWindow-box-text3__p {
  font-family: 'Montserrat', serif;
  font-weight: 700;
  font-size: 20px;
  color: #1C1712;
}
.FrPlan__orange {
  background-image: url("images/fp_orange.svg");
  height: 65px;
  width: 310px;
  margin-bottom: 60px;
  margin-left: 60px;
  align-self: start;
}
.FrPlan__white {
  background-image: url("images/fp_white.svg");
  height: 65px;
  width: 310px;
  margin-top: 60px;
  margin-left: 60px;
  align-self: start;
}
@media (max-width: 1200px) {
  .FrPlan__orange {
    align-self: center;
    margin-left: 0;
  }
  .FrPlan__white {
    align-self: center;
    margin-left: 0;
  }
}
@media (max-width: 700px) {
  .RegisterComponent {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .CheckEmailWindow-box-text {
    width: 380px;
    height: auto;
  }
  .VisionPasswordButton {
    margin-left: 90%;
  }
  .NoVisionPasswordButton {
    margin-left: 90%;
  }
  .login__page_to_forgetPage {
    margin-right: 0;
  }
}

.login__description {
  max-width: 550px;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  margin-bottom: 20px;
}

.login__remember-pass {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #000;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: -215px;

  span {
    color: #F7941D;
    cursor: pointer;
    text-decoration: underline;
  }
}

.password-validation__block {
  height: 0px;
  overflow: hidden;
  transition: height ease 0.5s;
  padding-top: 10px;
}

.password-validation__list {
  border-radius: 5px;
  margin-right: 250px;

  li {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    transition: background-color ease 0.5s;
    list-style-type: circle;
  }
}
