.MuiTabs-indicator,
.css-11yukd5-MuiTabs-indicator {
  color: black !important;
  background-color: #F7941D;
  border-bottom: 3px solid #F7941D;
}

.css-waqua-MuiButtonBase-root-MuiTab-root {
  flex-direction: row !important;
}

.MuiTab-root {
  color: black !important;
}

.profile-nav {
  margin-top: 20px;
}

.profile-tab {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  max-width: 300px !important;
  color: black !important;
  padding-top: 15px !important;
  text-transform: none !important;
  border-radius: 10px 10px 0 0 !important;
}

.Mui-selected {
  background-color: #F7941D !important;
  color: #FFFFFF !important;
}

.profile-tabs-box {

}

@media (max-width: 400px) {
  .css-heg063-MuiTabs-flexContainer {
    display: flex;
    flex-direction: column;
  }
}