.confirmation-modal__container {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgb(209,209,212, 0.5);;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;

  .confirmation-modal__content {
    padding: 30px 30px;
    border-radius: 15px;
    background-color: #FFFFFF;
    max-width: 910px;
    width: 100%;
    height: 383px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;

    .confirmation-modal__header-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #1C1712;
        margin: 0 0;
      }

      .icon {
        cursor: pointer;
        width: 30px;
        height: 30px;
      }
    }
  }
}

.off {
  opacity: 1;
  z-index: 3;
  pointer-events: all;
}

@media (max-width: 400px) {
  .confirmation-modal__content {
    height: 100% !important;
    justify-content: center !important;
  }
}