.profile-info__container {
  max-width: 1170px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto 0 auto;

  .profile-info__settings-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .profile-info__photo-upload {
      width: 180px;
      height: 180px;
      background: #FFFFFF;
      border: 3px dashed #A3A8C3;
      border-radius: 50%;
      gap: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .upload-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent !important;
      }

      p:nth-child(1) {
        font-family: 'Montserrat';
        font-style: normal;
        padding: 0 0;
        margin: 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15008px;
        color: #1C1712;
      }

      p:nth-child(2) {
        font-family: 'Montserrat';
        font-style: normal;
        padding: 0 0;
        margin: 0 0;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.15008px;
        color: #1C1712;
        opacity: 0.7;
      }
    }

    @media (max-width: 400px) {
      .profile-info__photo-upload {
        margin: auto;
      }
    }

    .profile-info__first-clm-container {
      max-width: 380px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 40px;

      .profile-info__select-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 380px;
        width: 100%;

        .profile-info__label {
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 130%;
          color: #4E598C;
          margin: 0 0;
          padding: 0 0;
        }

        .profile-info__select {
          max-width: 380px;
          width: 100%;
          font-family: 'Montserrat';
          border: 2px solid #A3A8C3;
          border-radius: 8px;
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 130%;
          color: #1C1712;
        }
      }
    }

    .profile-info__second-clm-container {
      max-width: 380px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 30px;

      .profile-info__chips-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 2px;
        max-width: 380px;
        width: 100%;
        min-height: 50px;
        align-items: flex-start;

        .chip {
          font-family: 'Karla';
          font-style: normal;
          font-weight: 300;
          font-size: 13px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #1C1712;
          background: #FFFFFF;
          border: 1px solid #A3A8C3;
          border-radius: 20px;
        }
      }
    }
  }

  .profile-info__radio-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 115px;
    margin-top: 40px;

    .profile-info__select-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 380px;
      width: 100%;

      .profile-info__label {
        padding: 5px 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 130%;
        color: #4E598C;
        margin: 0 0;
      }

      .profile-info__radio-box {
        .radio-button {
          margin: 0 0;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 400;
          font-size: 17px;
          line-height: 130%;
          color: #1C1712;

          span:nth-child(1) {
            color: #F7941D;
            padding: 5px 5px 5px 0;
          }
        }
      }
    }
  }

  .profile-info__btn-container {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}