.forgot-pass__container {
  max-width: 1180px;
  width: 100%;
  margin: 50px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .forgot-pass__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #000;
    margin-bottom: 20px;
  }

  .forgot-pass__description {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
  }

  .forgot-pass__button {
    transition: all .2s;
    height: 60px;
    width: 80%;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 60px;
    background: #F7941D;
    box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
    text-align: center;
    cursor: pointer;
    border: none;

    div {
      font-family: 'Karla', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 160%;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 1;
    }
  }

  .forgot-pass__remember-pass {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #000;
    margin-top: 30px;
    margin-bottom: 20px;

    span {
      color: #F7941D;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .forgot-pass__button:hover {
    background: #FFFFFF;
    border: 1px solid #F7941D;
    box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);

    div {
      color: #F7941D;
    }
  }

  .forgot-pass__button:disabled {
    transition: all .2s;
    height: 60px;
    width: 80%;
    border-radius: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-align: center;
    margin-top: 60px;

    div {
      font-family: 'Karla', serif;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 160%;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 1;
    }
    background: #F7941D;
    opacity: 0.4;
    box-shadow: 0 3px 10px rgba(247, 148, 29, 0.3);
  }

  .forgot-pass__button:focus {
    outline: none;
    border: none;
  }
}