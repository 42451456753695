html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, Montserrat, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  /*background-image: url("./components/dashboard/images/background.png") !important;*/
  background-color: #F8F8FA !important;
  /*background-repeat: no-repeat;*/
}

#root {
  width: 100%;
  height: 100%;
}

p,
span {
  font-family: Montserrat, serif;
}

/*@font-face {*/
/*  font-family: 'MyFont';*/
/*  src: local('MyFont'), url(./fonts/cour.ttf) format('ttf');*/
/*  !* other formats include: 'woff2', 'truetype, 'opentype',*/
/*                            'embedded-opentype', and 'svg' *!*/
/*}*/

@font-face {
  font-family: "Karla";
  src: local("Karla"), url("./fonts/Karla/Karla-Light.ttf") format("truetype");
  font-weight: normal;
}
