.recipes_cont {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.button_box {
  width: 1180px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.button_liked__active {
  transition: all .2s;
  height: 100px;
  width: 480px;
  background: url("images/liked_active.svg");
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.button_created__active {
  transition: all .2s;
  height: 100px;
  width: 480px;
  background: url("images/created_active.svg");
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.Click-button_liked__active {
  transition: all 0.2s;
  width: 220px;
  height: 100%;
  border-bottom: 1px solid rgba(247, 29, 29, 0);
}
.Click-button_liked__active:hover {
  transition: all 0.2s;
  border-bottom: 2px solid #F7941D;
}
.Click-button_created__active {
  transition: all 0.2s;
  width: 260px;
  height: 100%;
  border-bottom: 1px solid rgba(247, 29, 29, 0);
}
.Click-button_created__active:hover {
  transition: all 0.2s;
  border-bottom: 2px solid #4E598C;
}

@media (max-width: 1440px) {
  .button_box {
    width: 81%;
    align-items: center;
  }
}

